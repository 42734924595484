import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BusinessIcon from "@mui/icons-material/Business";
import TvIcon from "@mui/icons-material/Tv";
// import WebAssetIcon from '@mui/icons-material/WebAsset';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SummarizeIcon from "@mui/icons-material/Summarize";
// import EventRepeatIcon from '@mui/icons-material/EventRepeat';
// import ImportExportIcon from '@mui/icons-material/ImportExport';
// import ErrorIcon from '@mui/icons-material/Error';
import FacebookIcon from "@mui/icons-material/Facebook";
// import BlockIcon from '@mui/icons-material/Block';
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {Button} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";

const bigQueryTable = true;
const today = new Date();
const lastCompleteQDate = new Date(
  today.getFullYear(),
  today.getMonth() - 3,
  1
);
const lastCompleteQ = lastCompleteQDate.getMonth() / 3 + 1;
const lastCompleteQYear = lastCompleteQDate.getFullYear();

const defaultBQSettings = {
  bigQueryTable,
  today,
  lastCompleteQDate,
  lastCompleteQ,
  lastCompleteQYear,
};

export const appStructure = [
  {
    sectionName: "MANAGE",
    headers: [
      {
        pageName: "Deals",
        pageLink: "deals",
        navIcon: <BusinessCenterIcon />,
        columnsToShow: [
          "name",
          "custom_id_stem",
          "lds_owner",
          "report_checker",
          "share_to_client",
          "latest_report",
          "share_to_lds",
          "fixed_fee",
          "add_recoupment",
          "contract_type",
          "reports_type",
          "client",
          // "client",
          // "client_id",
          // "client_rev_share",
          // "currency",
          // "custom_id_stem",
          // "lds_owner",
          // "min_guarantee",
          // "name",
          // "permitted_deduction",
          // "report_currency",
          // "report_emails",
          // "report_type",
          "can_send",
        ],
      },
      {
        pageName: "Programmes",
        pageLink: "programmes",
        navIcon: <TvIcon />,
        columnsToShow: [
          "title",
          "deal",
          "custom_id",
          "asset_count",
          "platforms",
        ],
      },
      // {
      //   pageName: "Assets", pageLink: "assets", navIcon: <WebAssetIcon />, columnsToShow: [
      //     'asset_id',
      //     'source',
      //     'channel',
      //     'title',
      //     'deal_title',
      //     'programme_title',
      //     'series',
      //     'episode',
      //     'custom_id'
      //   ],
      //   defaultParams: {
      //     row_id: 'asset_id'
      //   }
      // },
      {
        pageName: "Assets",
        pageLink: "assets",
        navIcon: <AccountBalanceIcon />,
        ...defaultBQSettings,
        columnsToShow: [
          "assetsId",
          "client_1",
          "programme_1",
          "series_1",
          "episode_1",
          "custom_id",
        ],
        CustomFilter: {
          assetsId:[],
          client_1: [],
          programme_1: [],
          custom_id: [],
          series_1: [],
          source: [
            {label: "YouTube", value: "yt"},
            {label: "Facebook", value: "fb"},
          ],
          cms: [
            {label: "lds", value: "Lds"},
            {label: "Main", value: "main"},
            {label: "Deutschland", value: "deutschland"},
            {label: "Family", value: "family"},
            {label: "Sport", value: "sports"},
            {label: "Misc", value: "misc"},
            {label: "Affiliate", value: "affiliate"},
            {label: "Sony Pictures", value: "sonypictures"},
            {label: "Intl Para Comm", value: "intlparacomm"},
            {label: "US Affiliate", value: "usaffiliate"},
            {label: "Sony Kids", value: "sony_kids"},
            {label: "A&E DE", value: "ae_de"},
          ],
        },
      },
      // {
      //   pageName: "Unknown Assets",
      //   pageLink: "unknown_assets",
      //   ...defaultBQSettings,
      //   navIcon: <QuestionMarkIcon />,
      //   columnsToShow: [
      //     "asset_id",
      //     "programme",
      //     "revenue",
      //     "quarter",
      //     "year",
      //     "source",
      //     "cms",
      //   ],
      //   CustomFilter: {
      //     client: [],
      //     programme: [],
      //     custom_id: [],
      //     series: [],
      //     source: [
      //       {label: "YouTube", value: "Youtube"},
      //       {label: "Facebook", value: "Facebook"},
      //     ],
      //     cms: [
      //       {label: "Main", value: "lds"},
      //       {label: "Deutschland", value: "lds_de"},
      //       {label: "Family", value: "family"},
      //       {label: "Sport", value: "lds_sport"},
      //       {label: "Misc", value: "lds_misc"},
      //       {label: "Affiliate", value: "lds_affiliate"},
      //       {label: "Sony Pictures", value: "sony_pictures"},
      //       {label: "Intl Para Comm", value: "lds_affiliate_ipc"},
      //       {label: "US Affiliate", value: "lds_affiliate_us"},
      //       {label: "Sony Kids", value: "sony_kids"},
      //     ],
      //   },
      // },

      //As per the Aravind's suggestion hide the Pending Changes menu
      // {
      //   pageName: "Pending Changes",
      //   pageLink: "changes",
      //   navIcon: <ChangeCircleIcon />,
      //   columnsToShow: ["asset_id", "new_custom_id", "old_custom_id"],
      //   defaultParams: {
      //     row_id: "asset_id",
      //   },
      // },
    ],
  },
  {
    sectionName: "REPORT",
    headers: [
      {
        pageName: "Reports Sharing",
        pageLink: "reports",
        navIcon: <ShareIcon />,
        columnsToShow: [
          "client",
          "deal",
          "can_send",
          "year",
          "quarter",
          "report_file",
          "sent",
          // "status",
          // "outcome",
          // "report_file",
          // "job",
        ],
        // customColumns: {
        //   report_file: {
        //     renderCell: ({row}) =>
        //       <Button
        //         disabled={row.report_file === '' || !row.report_file}
        //         component='a'
        //         href={row.report_file}
        //         target="_blank"
        //         rel="noopener noreferrer"
        //       >
        //         Email Report
        //       </Button>,
        //     headerName: 'Report Link'
        //   }

        // }
      },

      {
        pageName: "Report History",
        pageLink: "job",
        navIcon: <SummarizeIcon />,
        columnsToShow: [
          "id",
          "year",
          "quarter",
          "task_count",
          "successful_reports",
          "errors",
          "job_folder",
        ],
        customColumns: {
          job_folder: {
            renderCell: ({row}) => (
              <Button
                disabled={row.job_folder === "" || !row.job_folder}
                component="a"
                href={row.job_folder}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Folder
              </Button>
            ),
            headerName: "Folder Link",
          },
        },
      },
      // {
      //   pageName: "Quarterly Reports",
      //   pageLink: "quarterly_report",
      //   navIcon: <SummarizeIcon />,
      //   ...defaultBQSettings,
      //   // customColumns: {
      //   //   report_file: {
      //   //     renderCell: ({row}) => (
      //   //       <Button
      //   //         disabled={row.report_file === "" || !row.report_file}
      //   //         component="a"
      //   //         href={row.report_file}
      //   //         target="_blank"
      //   //         rel="noopener noreferrer"
      //   //       >
      //   //         View Report
      //   //       </Button>
      //   //     ),
      //   //     headerName: "Report Link",
      //   //   },
      //   // },
      //   columnsToShow: [
      //     "name",
      //     "gross_revenue_client_currency",
      //     "gross_revenue_usd",
      //     "year",
      //     "quarter",
      //     "Source",
      //   ],
      //   // CustomFilter: {
      //   //   Id: [],
      //   //   Name: [],
      //   //   Gross_Revenue_Client_Currency: [],
      //   //   Gross_Revenue_Usd: [],
      //   //   Quarter: [],
      //   //   Year: [],
      //   //   Source: [],
      //   // },
      // },
      {
        pageName: "Exchange Rates",
        pageLink: "exchange_rates",
        navIcon: <CurrencyExchangeIcon />,
        columnsToShow: ["entry_date", "rate", "currency", "id"],
      },
      {
        pageName: "Reports",
        pageLink: "task",
        navIcon: <TvIcon />,
        hidden: true,
        columnsToShow: [
          "client",
          "deal",
          "status",
          "outcome",
          "report_file",
          "job",
        ],
        customColumns: {
          report_file: {
            renderCell: ({row}) => (
              <Button
                disabled={row.report_file === "" || !row.report_file}
                component="a"
                href={row.report_file}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Report
              </Button>
            ),
            headerName: "Report Link",
          },
        },
      },
      // {
      //   pageName: "Quarterly Revenue", pageLink: "quarterly-revenue", navIcon: <EventRepeatIcon />, ...defaultBQSettings,
      //   params: `?year=${defaultBQSettings.lastCompleteQYear}&quarter=${defaultBQSettings.lastCompleteQ}`,
      //   CustomFilter: [
      //     'year',
      //     'quarter',
      //     'source'
      //   ],
      //   columnsToShow: [
      //     'asset_id',
      //     'quarter_revenue',
      //     'quarter',
      //     'source',
      //     'year'
      //   ],
      // },
      // { pageName: "Monthly Revenue", pageLink: "monthly-revenue", navIcon: <EventRepeatIcon />, ...defaultBQSettings },
    ],
  },
  // {
  //   sectionName: 'IMPORT',
  //   headers: [
  //     { pageName: "Import", pageLink: "import", navIcon: <ImportExportIcon /> },
  //     { pageName: "Import Programmes", pageLink: "import-programmes", navIcon: <ImportExportIcon /> },
  //     { pageName: "Import Errors", pageLink: "import-errors", navIcon: <ErrorIcon /> },
  //   ]
  // },
  {
    sectionName: "GOOGLE SHEETS",
    headers: [
      {
        pageName: "Facebook Pages",
        pageLink: "channels",
        params: "?source=facebook",
        navIcon: <FacebookIcon />,
        columnsToShow: ["source", "title", "channel_id", "description"],
      },
      // { pageName: "Excluded Facebook Pages", pageLink: "channels", params: "?source=facebook&excluded=true", navIcon: <FacebookIcon /> },
      // {
      //   pageName: "YouTube Paid Features",
      //   pageLink: "channels",
      //   params: "?source=youtube&paid_feature=true",
      //   navIcon: <YouTubeIcon />,
      // },
    ],
  },
];

export const appStructureSuperUser = [
  {
    sectionName: "ACCESS",
    headers: [
      {
        pageName: "Clients",
        pageLink: "clients",
        navIcon: <BusinessIcon />,
        columnsToShow: ["name", "email", "phone", "mobile"],
      },
    ]
  },
  {
    sectionName: "MANAGE",
    headers: [
    
      {
        pageName: "Deals",
        pageLink: "deals",
        navIcon: <BusinessCenterIcon />,
        columnsToShow: [
          "name",
          "custom_id_stem",
          "lds_owner",
          "report_checker",
          "share_to_client",
          "latest_report",
          "share_to_lds",
          "fixed_fee",
          "add_recoupment",
          "contract_type",
          "reports_type",
          "client",
          // "client",
          // "client_id",
          // "client_rev_share",
          // "currency",
          // "custom_id_stem",
          // "lds_owner",
          // "min_guarantee",
          // "name",
          // "permitted_deduction",
          // "report_currency",
          // "report_emails",
          // "report_type",
        ],
      },
      {
        pageName: "Programmes",
        pageLink: "programmes",
        navIcon: <TvIcon />,
        columnsToShow: [
          "title",
          "deal",
          "custom_id",
          "asset_count",
          "platforms",
        ],
      },
      // {
      //   pageName: "Assets", pageLink: "assets", navIcon: <WebAssetIcon />, columnsToShow: [
      //     'asset_id',
      //     'source',
      //     'channel',
      //     'title',
      //     'deal_title',
      //     'programme_title',
      //     'series',
      //     'episode',
      //     'custom_id'
      //   ],
      //   defaultParams: {
      //     row_id: 'asset_id'
      //   }
      // },
      {
        pageName: "Assets",
        pageLink: "assets",
        navIcon: <AccountBalanceIcon />,
        ...defaultBQSettings,
        columnsToShow: [
          "assetsId",
          "client_1",
          "programme_1",
          "series_1",
          "episode_1",
          "custom_id",
        ],
        CustomFilter: {
          assetsId:[],
          client_1: [],
          programme_1: [],
          custom_id: [],
          series_1: [],
          source: [
            {label: "YouTube", value: "yt"},
            {label: "Facebook", value: "fb"},
          ],
          cms: [
            {label: "lds", value: "Lds"},
            {label: "Main", value: "main"},
            {label: "Deutschland", value: "deutschland"},
            {label: "Family", value: "family"},
            {label: "Sport", value: "sports"},
            {label: "Misc", value: "misc"},
            {label: "Affiliate", value: "affiliate"},
            {label: "Sony Pictures", value: "sonypictures"},
            {label: "Intl Para Comm", value: "intlparacomm"},
            {label: "US Affiliate", value: "usaffiliate"},
            {label: "Sony Kids", value: "sony_kids"},
            {label: "A&E DE", value: "ae_de"},
          ],
        },
      },
      // {
      //   pageName: "Unknown Assets",
      //   pageLink: "unknown_assets",
      //   ...defaultBQSettings,
      //   navIcon: <QuestionMarkIcon />,
      //   columnsToShow: [
      //     "asset_id",
      //     "programme",
      //     "revenue",
      //     "quarter",
      //     "year",
      //     "source",
      //     "cms",
      //   ],
      //   CustomFilter: {
      //     client: [],
      //     programme: [],
      //     custom_id: [],
      //     series: [],
      //     source: [
      //       {label: "YouTube", value: "Youtube"},
      //       {label: "Facebook", value: "Facebook"},
      //     ],
      //     cms: [
      //       {label: "Main", value: "lds"},
      //       {label: "Deutschland", value: "lds_de"},
      //       {label: "Family", value: "family"},
      //       {label: "Sport", value: "lds_sport"},
      //       {label: "Misc", value: "lds_misc"},
      //       {label: "Affiliate", value: "lds_affiliate"},
      //       {label: "Sony Pictures", value: "sony_pictures"},
      //       {label: "Intl Para Comm", value: "lds_affiliate_ipc"},
      //       {label: "US Affiliate", value: "lds_affiliate_us"},
      //       {label: "Sony Kids", value: "sony_kids"},
      //     ],
      //   },
      // },
      
      //As per the Aravind's suggestion hide the Pending Changes menu
      // {
      //   pageName: "Pending Changes",
      //   pageLink: "changes",
      //   navIcon: <ChangeCircleIcon />,
      //   columnsToShow: ["asset_id", "new_custom_id", "old_custom_id"],
      //   defaultParams: {
      //     row_id: "asset_id",
      //   },
      // },
    ],
  },
  {
    sectionName: "REPORT",
    headers: [
      {
        pageName: "Reports Sharing",
        pageLink: "reports",
        navIcon: <ShareIcon />,
        columnsToShow: [
          "client",
          "deal",
          "cansend",
          "year",
          "quarter",
          "report_file",
          "sent",
          // "status",
          // "outcome",
          // "report_file",
          // "job",
        ],
        // customColumns: {
        //   report_file: {
        //     renderCell: ({row}) =>
        //       <Button
        //         disabled={row.report_file === '' || !row.report_file}
        //         component='a'
        //         href={row.report_file}
        //         target="_blank"
        //         rel="noopener noreferrer"
        //       >
        //         Email Report
        //       </Button>,
        //     headerName: 'Report Link'
        //   }

        // }
      },

      {
        pageName: "Report History",
        pageLink: "job",
        navIcon: <SummarizeIcon />,
        columnsToShow: [
          "id",
          "year",
          "quarter",
          "task_count",
          "successful_reports",
          "errors",
          "job_folder",
        ],
        customColumns: {
          job_folder: {
            renderCell: ({row}) => (
              <Button
                disabled={row.job_folder === "" || !row.job_folder}
                component="a"
                href={row.job_folder}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Folder
              </Button>
            ),
            headerName: "Folder Link",
          },
        },
      },
      // {
      //   pageName: "Quarterly Reports",
      //   pageLink: "quarterly_report",
      //   navIcon: <SummarizeIcon />,
      //   ...defaultBQSettings,
      //   // customColumns: {
      //   //   report_file: {
      //   //     renderCell: ({row}) => (
      //   //       <Button
      //   //         disabled={row.report_file === "" || !row.report_file}
      //   //         component="a"
      //   //         href={row.report_file}
      //   //         target="_blank"
      //   //         rel="noopener noreferrer"
      //   //       >
      //   //         View Report
      //   //       </Button>
      //   //     ),
      //   //     headerName: "Report Link",
      //   //   },
      //   // },
      //   columnsToShow: [
      //     "name",
      //     "gross_revenue_client_currency",
      //     "gross_revenue_usd",
      //     "year",
      //     "quarter",
      //     "Source",
      //   ],
      //   // CustomFilter: {
      //   //   Id: [],
      //   //   Name: [],
      //   //   Gross_Revenue_Client_Currency: [],
      //   //   Gross_Revenue_Usd: [],
      //   //   Quarter: [],
      //   //   Year: [],
      //   //   Source: [],
      //   // },
      // },
      {
        pageName: "Exchange Rates",
        pageLink: "exchange_rates",
        navIcon: <CurrencyExchangeIcon />,
        columnsToShow: ["entry_date", "rate", "currency", "id"],
      },
      {
        pageName: "Reports",
        pageLink: "task",
        navIcon: <TvIcon />,
        hidden: true,
        columnsToShow: [
          "client",
          "deal",
          "status",
          "outcome",
          "report_file",
          "job",
        ],
        customColumns: {
          report_file: {
            renderCell: ({row}) => (
              <Button
                disabled={row.report_file === "" || !row.report_file}
                component="a"
                href={row.report_file}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Report
              </Button>
            ),
            headerName: "Report Link",
          },
        },
      },
      // {
      //   pageName: "Quarterly Revenue", pageLink: "quarterly-revenue", navIcon: <EventRepeatIcon />, ...defaultBQSettings,
      //   params: `?year=${defaultBQSettings.lastCompleteQYear}&quarter=${defaultBQSettings.lastCompleteQ}`,
      //   CustomFilter: [
      //     'year',
      //     'quarter',
      //     'source'
      //   ],
      //   columnsToShow: [
      //     'asset_id',
      //     'quarter_revenue',
      //     'quarter',
      //     'source',
      //     'year'
      //   ],
      // },
      // { pageName: "Monthly Revenue", pageLink: "monthly-revenue", navIcon: <EventRepeatIcon />, ...defaultBQSettings },
    ],
  },
  // {
  //   sectionName: 'IMPORT',
  //   headers: [
  //     { pageName: "Import", pageLink: "import", navIcon: <ImportExportIcon /> },
  //     { pageName: "Import Programmes", pageLink: "import-programmes", navIcon: <ImportExportIcon /> },
  //     { pageName: "Import Errors", pageLink: "import-errors", navIcon: <ErrorIcon /> },
  //   ]
  // },
  {
    sectionName: "GOOGLE SHEETS",
    headers: [
      {
        pageName: "Facebook Pages",
        pageLink: "channels",
        params: "?source=facebook",
        navIcon: <FacebookIcon />,
        columnsToShow: ["source", "title", "channel_id", "description"],
      },
      // { pageName: "Excluded Facebook Pages", pageLink: "channels", params: "?source=facebook&excluded=true", navIcon: <FacebookIcon /> },
      // {
      //   pageName: "YouTube Paid Features",
      //   pageLink: "channels",
      //   params: "?source=youtube&paid_feature=true",
      //   navIcon: <YouTubeIcon />,
      // },
    ],
  },
  {
    sectionName: "ACCESS",
    headers: [
      {
        pageName: "Permission",
        pageLink: "users",
        navIcon: <AdminPanelSettingsIcon />,
        columnsToShow: [
          "username",
           "email",
           "role",
        ],
      },
    ]
  }  
  
];

export const appStructureNew = [
  {
    sectionName: "MANAGE",
    headers: [
      {
        pageName: "Programmes",
        pageLink: "programmes",
        navIcon: <TvIcon />,
        columnsToShow: [
          "title",
          "deal",
          "custom_id",
          "asset_count",
          "platforms",
        ],
      }
      
    ],
  },
];
