import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import * as XLSX from 'xlsx';
import sendRequest from "../services/sendRequest";
import MoonLoader from "react-spinners/MoonLoader";

const ExchangeRatesUpload =({open, close, selected, setRefresh}) => {
const [items, setItems] = useState([]);
const [formData, setFormData] = useState({});
const { enqueueSnackbar } = useSnackbar()
const [fullWidth, setFullWidth] = React.useState(true);
const [maxWidth, setMaxWidth] = React.useState('sm');
let [loading, setLoading] = useState(false);
let [color, setColor] = useState("#000000");
const page ="exchange_rates";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
const handleClose = () => {
    close();
  };

  useEffect(() => {
  });

  // handle file upload
  const handleFileUpload = e => {
    let isValidRow = true;
    let isColumnsMissing = true;
    setLoading(true)
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const Papa = require('papaparse');
      const fileData = XLSX.utils.sheet_to_csv(ws, { header: 1 });

      // Parse the CSV data
      const parsedData = Papa.parse(fileData, {
        header: false, // Set to true if the first row contains headers
      });

      // Initialize an array to hold column-wise data
      let columns = [];
      const items = [];

      // Iterate through each row
      parsedData.data.forEach((row, rowIndex) => {
        // Iterate through each cell and organize them into columns
        if (row.length != 3) {
          isValidRow = false;
        }
        row.forEach((cell, colIndex) => {
          // Initialize the column array if it doesn't exist
          if (!columns[colIndex]) {
            columns[colIndex] = [];
          }
          // Add the cell to the appropriate column
          columns[colIndex][rowIndex] = cell;
        });
        // Create an object for each row
        if (row[0] === "" && row[1] === "" && row[2] === "") {
          // All values are empty, do nothing
        } else if (row[0] === "" || row[1] === "" || row[2] === "") {
          isColumnsMissing = true;
          isValidRow = false;
        } else {
          const newObject = {
            currency: row[0],
            date: row[1],
            rate: row[2],
          };
          items.push(newObject);
        }
        
      });
      console.log(items);
      if (isValidRow) {
        try {      
          const response = await sendRequest({
            requestType: "post",
            endpoint: `${page}/custom_bulk_create/`,
            requestData: items,
          });
      
          if (response.status === 200) {
            setItems([]);
            if(response && response.data && response.data.er_list) {
              localStorage.setItem('bulk_exchange_rates',JSON.stringify(response.data.er_list));
            }
            handleSuccess(response.data.message);
            handleClose();
          } else {
            handleError();
          }
      
        } catch (error) {
          console.error("An error occurred:", error);
          handleError();
        } finally {
          setItems([]);
          setLoading(false);
          handleClose();
        }
      } else {
        if(isColumnsMissing) {
          setItems([]);
          handleErrorForMoreThanTwoCoulumnsMissting();
          setLoading(false)
          handleClose();
        } else {
          setItems([]);
          handleErrorForMoreThanTwoCoulumnsInFile();
          setLoading(false)
          handleClose();
        }
        
      }
    };
    reader.readAsBinaryString(file);
  }

  const handleError = () => {
    enqueueSnackbar('There is an Error with the file, Please Check the .csv file and Reupload it!', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 5000,
    });
  };

  const handleErrorForMoreThanTwoCoulumnsInFile = () => {
    enqueueSnackbar('Ensure that the file contains only these 3 Columns [Currency, Date of entry, Exchange Rate] in the specified order and does not include any headers', {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 5000,
    });
  };

  const handleErrorForMoreThanTwoCoulumnsMissting = () => {
    enqueueSnackbar('Some of the exchange rate records have empty details. Please fill them in and re-upload the file', {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 5000,
    });
  };

  const handleSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 3000,
    });
  };
  return (
    <Dialog
    open={open}
    onClose={() => {
      close();
    }}
    >
      <DialogContent>
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
          >
            <MoonLoader
              color={color}
              loading={loading}
              cssOverride={override}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <DialogTitle sx={{ textAlign: 'center' }}>Upload CSV</DialogTitle>
            <DialogContent sx={{ marginBottom: 2 }}>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
              Upload a CSV file to Create the new Exchange Rates!
              </DialogContentText>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
              <b>The .CSV file with no headers should contain the following columns in the specified order: 1) Currency, 2) Date of entry(MM/DD/YYYY), 3) Exchange Rate.</b>
              </DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: 'fit-content',
                }}
              >        
              <FormControl sx={{ mt: 2, minWidth: 120 }}>              
                <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload}/>
              </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default ExchangeRatesUpload;
